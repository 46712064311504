import React from 'react';
import {createRoot} from 'react-dom/client';
import JshModule from '@core/scripts/helper/jsh-module';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import SearchField from "../../components/search/SearchField.jsx";
import {createSyncStoragePersister} from "@tanstack/query-sync-storage-persister";
import {persistQueryClient} from "@tanstack/react-query-persist-client";
const {moduleScope, config} = JshModule('search-field');

const queryClient = new QueryClient();
const sessionStoragePersister = createSyncStoragePersister({
    key: 'SEARCH-CACHE',
    storage: window.sessionStorage,
})
persistQueryClient({
    queryClient,
    persister: sessionStoragePersister,
});

createRoot(moduleScope).render(
    <QueryClientProvider client={queryClient}>
        <SearchField searchUrl={config.searchUrl} noResultsFound={config.noResultsFound}/>
    </QueryClientProvider>,
);
